import React from "react"
import Styled from "./MenuHamburger.styled"

type MenuHamburgerProps = {
  isOpened: boolean
}

const MenuHamburger = ({ isOpened }: MenuHamburgerProps) => {
  return (
    <Styled.Wrapper>
      <Styled.Bar top isOpened={isOpened} />
      <Styled.Bar middle isOpened={isOpened} />
      <Styled.Bar bottom isOpened={isOpened} />
    </Styled.Wrapper>
  )
}

export default MenuHamburger
