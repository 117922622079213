import styled from "styled-components"
import Logo from "../../components/Logo"

const FooterLogo = styled(Logo)`
  height: 32px;
`

const Layout = styled.div`
  padding-top: 60px;
  overflow: hidden;
`

const Content = styled.div`
  min-height: calc(100vh - 112px);

  overflow: hidden;
`
const Footer = styled.footer`
  height: 52px;
  background-color: ${props => props.theme.color.primaryLight};
  display: flex;
  align-items: center;
  justify-content: stretch;
`

const Container = styled.div`
  max-width: 800px;
  margin: auto;
  width: calc(100% - 48px);
`

const Styled = {
  Layout,
  Content,
  Container,
  Footer,
  FooterLogo,
}

export default Styled
