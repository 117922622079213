import styled from "styled-components"
import { Link } from "gatsby"
import Logo from "components/Logo"
import Row from "components/Row"
import WIcon from "components/WIcon"

const MenuLogo = styled(Logo)`
  height: 40px;
`

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 2px solid black;
  padding: 0 32px;
  z-index: 1000;
  background-color: #fff;
`

const MenuLink = styled(Link).attrs({
  activeClassName: "active",
})`
  margin-left: 32px;
  font-family: inherit;
  cursor: pointer;
  text-decoration: none;
  color: black;
  display: inline-flex;
  align-items: center;
  position: relative;

  &.active {
    font-weight: bold;
  }

  &::before {
    position: absolute;
    content: "";
    bottom: -4px;
    top: -4px;
    left: -12px;
    right: -12px;
    margin: auto;
    border-radius: 5px;
    opacity: 0;
    z-index: -1;
    background-color: ${props => props.theme.color.primaryLight};
  }

  &:hover::before {
    opacity: 1;
  }

  &.active:hover::before {
    opacity: 0;
  }
`

const MobileLoginLink = styled(Link).attrs({
  activeClassName: "active",
})`
  font-family: inherit;
  cursor: pointer;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 60px;
  border-top: 1px solid #000;
  margin: 0 -96px 0 -24px;

  &:hover {
    background-color: ${props => props.theme.color.primaryLight};
  }
`

const MenuAccount = styled(Row)`
  height: 60px;
  border-left: 2px solid #000;
  display: inline-flex;
  margin-left: 32px;
`

const MobileMenuAccount = styled(Row)`
  height: 60px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const MenuLinkIcon = styled(WIcon)`
  margin-right: 8px;
  position: relative;
`

const MobileOverlay = styled.div<{ isOpened: boolean }>`
  position: fixed;
  z-index: 900;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${props => (props.isOpened ? 1 : 0)};
  transition: opacity 0.3s, transform 0s ${props => (props.isOpened ? 0 : 0.2)}s;
  transform: translateX(${props => (props.isOpened ? 0 : -100)}%);
`

const MobileBar = styled.div`
  border-bottom: 1px solid #000;
  position: fixed;
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  background-color: #fff;
`

const MobileBarLogo = styled(Logo)`
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 134px;
`

const MobileMenuButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  font-size: 24px;
  line-height: 1;
  padding: 0;
  display: flex;
  align-items: center;
`
const PageName = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  margin: auto;
`

const CollapseMenu = styled.div<{ isMobileOpened: boolean }>`
  position: fixed;
  left: 0;
  top: 70px;
  bottom: 0;
  width: auto;
  padding: 24px 96px 0 24px;
  z-index: 1000;
  background-color: white;
  overflow-y: auto;
  transition: transform 0.2s ease-out;
  transform: translateX(-101%);
  display: grid;
  grid-template-rows: 1fr 60px;

  ${props =>
    props.isMobileOpened &&
    `
    transform: translateX(0);
  `}
`

const Styled = {
  CollapseMenu,
  MobileMenuAccount,
  MenuAccount,
  Menu,
  MenuLogo,
  MenuLink,
  MenuLinkIcon,
  MobileOverlay,
  MobileBar,
  MobileBarLogo,
  MobileMenuButton,
  MobileLoginLink,
  PageName,
}

export default Styled
