import React, { FC } from "react"
import Styled from "./HomeLayout.styled"
import Theme from "../../theme/Theme"
import { ViewContextProvider } from "../../context/viewContext"
import Row from "../../components/Row"
import HomeMenuBar from "components/HomeMenuBar"
import { AudioPlayerContextProvider } from "components/AudioPlayer/AudioPlayer"

const HomeLayout: FC = ({ children }) => {
  return (
    <ViewContextProvider>
      <AudioPlayerContextProvider>
        <Theme>
          <Styled.Layout>
            <HomeMenuBar />
            <Styled.Content>{children}</Styled.Content>
            <Styled.Footer>
              <Styled.Container>
                <Row
                  style={{ width: "100%" }}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Styled.FooterLogo />
                  <div>
                    ks. Wojciech Węgrzyniak © 2021 – Wszelkie prawa zastrzeżone.
                  </div>
                </Row>
              </Styled.Container>
            </Styled.Footer>
          </Styled.Layout>
        </Theme>
      </AudioPlayerContextProvider>
    </ViewContextProvider>
  )
}

export default HomeLayout
