import React, { FC, ReactNode, useState } from "react"
import { Link } from "gatsby"
import Styled from "./NavigationItem.styled"
import Icon from "components/Icon"

export type NavigationItemProps = {
  to: string
  title: string
  icon?: string
  path?: string
  submenu?: ReactNode
  level: number
  link?: boolean
  isActive?: boolean
  onClick?: (e: React.MouseEvent) => void
  targetBlank?: boolean
}

const NavigationItem: FC<NavigationItemProps> = ({
  to,
  title,
  icon,
  submenu,
  level,
  link,
  isActive,
  onClick,
  targetBlank,
}) => {
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  const linkProps = link
    ? {
        href: to ? to : "#",
      }
    : {
        to: to,
      }

  return (
    <Styled.Wrapper level={level}>
      <Styled.NavigationLink
        {...linkProps}
        as={link ? "a" : Link}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isActive={isActive}
        onClick={onClick}
        target={targetBlank && "_blank"}
      >
        {level === 1 && (
          <Styled.NavigationIcon isActive={isActive} isHovering={isHovering}>
            {icon && <Icon icon={icon} />}
          </Styled.NavigationIcon>
        )}
        {level === 2 && (
          <Styled.ActiveCircle isActive={isActive} isHovering={isHovering} />
        )}
        {title}
      </Styled.NavigationLink>

      {submenu}
    </Styled.Wrapper>
  )
}

export default NavigationItem
