import styled from "styled-components"
import logoUrl from "./logo.svg"
import React, { HTMLAttributes } from "react"

const LogoImg = styled.img``

type LogoProps = HTMLAttributes<HTMLImageElement>

const Logo = (props: LogoProps) => <LogoImg src={logoUrl} {...props} />

export default Logo
