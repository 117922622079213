import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  padding-bottom: 56.25%;
`

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

type ResponsiveEmbedProps = {
  src: string
}

const ResponsiveEmbed = ({ src }: ResponsiveEmbedProps) => {
  return (
    <Wrapper>
      <IFrame
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        src={src}
      />
    </Wrapper>
  )
}

export default ResponsiveEmbed
