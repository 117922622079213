import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
`

const IFrame = styled.iframe`
  position: absolute;
  top: -46px;
  left: 0;
  width: 100%;
  height: calc(100% + 46px);
  border: 0;
`

type ResponsiveMapProps = {
  src: string
}

const ResponsiveMap = ({ src }: ResponsiveMapProps) => {
  return (
    <Wrapper>
      <IFrame
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        src={src}
      />
    </Wrapper>
  )
}

export default ResponsiveMap
