import Styled from "./HomeMenuBar.styled"
import Row from "components/Row"
import React, { FC, useState } from "react"
import { personOutline } from "ionicons/icons"
import MenuHamburger from "components/MenuHamburger/MenuHamburger"
import { useLocation } from "@reach/router"
import { ViewContextConsumer } from "context/viewContext"
import Navigation, { NavigationItem } from "components/Navigation"
import removeTrailingSlash from "tools/removeTrailingSlash"
import { graphql, Link, useStaticQuery } from "gatsby"
import Button from "components/Button"

type HomeMobileMenuBarProps = {
  isMobileMenuOpened: boolean
  setIsMobileMenuOpened: (value: boolean) => void
}

const HomeMobileMenuBar: FC<HomeMobileMenuBarProps> = ({
  isMobileMenuOpened,
  setIsMobileMenuOpened,
}) => {
  const handleMobileMenuToggle = () =>
    setIsMobileMenuOpened(!isMobileMenuOpened)

  return (
    <>
      <Styled.MobileOverlay
        isOpened={isMobileMenuOpened}
        onClick={() => setIsMobileMenuOpened(false)}
      />
      <Styled.MobileBar>
        <Styled.MobileBarLogo />
        <Styled.MobileMenuButton onClick={handleMobileMenuToggle}>
          <MenuHamburger isOpened={isMobileMenuOpened} />
        </Styled.MobileMenuButton>
      </Styled.MobileBar>
    </>
  )
}

const HomeMenuBar = () => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)
  const location = useLocation()

  const data = useStaticQuery(graphql`
    query {
      strapi {
        homepage {
          menu {
            title
            slug
          }
        }
      }
    }
  `)

  return (
    <ViewContextConsumer>
      {viewContext => {
        const isMobile = !!(
          viewContext?.media.xs ||
          viewContext?.media.sm ||
          viewContext?.media.md
        )
        const isDesktop = !!(viewContext?.media.lg || viewContext?.media.xl)
        return (
          viewContext && (
            <>
              {isMobile && (
                <>
                  <HomeMobileMenuBar
                    setIsMobileMenuOpened={setIsMobileMenuOpened}
                    isMobileMenuOpened={isMobileMenuOpened}
                  />
                  <Styled.CollapseMenu isMobileOpened={isMobileMenuOpened}>
                    <Navigation>
                      <NavigationItem
                        to={"/"}
                        title={"Strona główna"}
                        isActive={
                          removeTrailingSlash(location.pathname).indexOf(
                            "/"
                          ) === 0
                        }
                        level={2}
                      />
                      {data.strapi.homepage.menu.map(item => (
                        <NavigationItem
                          key={item.slug}
                          to={`/${item.slug}`}
                          title={item.title.toLowerCase()}
                          isActive={
                            removeTrailingSlash(location.pathname).indexOf(
                              `/${item.slug}`
                            ) === 0
                          }
                          level={2}
                        />
                      ))}

                      <NavigationItem
                        to={"/przykladowe-spotkanie"}
                        title={"przykładowe spotkanie"}
                        isActive={
                          removeTrailingSlash(location.pathname).indexOf(
                            "/przykladowe-spotkanie"
                          ) === 0
                        }
                        level={2}
                      />
                    </Navigation>
                    <Styled.MobileLoginLink to={"/konto"}>
                      <Styled.MenuLinkIcon icon={personOutline} /> Strefa
                      użytkownika
                    </Styled.MobileLoginLink>
                  </Styled.CollapseMenu>
                </>
              )}
              {isDesktop && (
                <Styled.Menu>
                  <div>
                    <Link to={"/"}>
                      <Styled.MenuLogo />
                    </Link>
                  </div>
                  <Row>
                    <Styled.MenuLink to={"/"}>strona główna</Styled.MenuLink>
                    {data.strapi.homepage.menu.map(item => (
                      <Styled.MenuLink key={item.slug} to={`/${item.slug}`}>
                        {item.title.toLowerCase()}
                      </Styled.MenuLink>
                    ))}
                    <Styled.MenuAccount>
                      <Styled.MenuLink to={"/konto"}>
                        <Styled.MenuLinkIcon icon={personOutline} /> Strefa
                        użytkownika
                      </Styled.MenuLink>
                    </Styled.MenuAccount>
                  </Row>
                </Styled.Menu>
              )}
            </>
          )
        )
      }}
    </ViewContextConsumer>
  )
}

export default HomeMenuBar
