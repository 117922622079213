import styled from "styled-components"

const Wrapper = styled.li<{ level: number }>`
  position: relative;
  padding-left: ${props => (props.level - 1) * 48}px;
`

const NavigationLink = styled.a<{ isActive?: boolean }>`
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 2px 0;
  height: 30px;
  -webkit-text-stroke-width: ${props => (props.isActive ? 1 : 0)}px;
`

const NavigationIcon = styled.div<{ isActive?: boolean; isHovering: boolean }>`
  width: 30px;
  height: 30px;
  position: relative;
  margin-right: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;

  &::before {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${props => (props.isActive ? "100%" : "4px")};
    display: block;
    transition-duration: 0.2s;
    border-radius: ${props => (props.isActive ? "50%" : "2px")};
    content: "";
    background-color: ${props => props.theme.color.primaryLight};
    transform: ${props =>
      props.isActive
        ? "scale(1)"
        : props.isHovering
        ? "translateY(-4px) scale(1)"
        : "translateY(-4px) scale(0)"};
  }
`

const ActiveCircle = styled.div<{ isActive?: boolean; isHovering: boolean }>`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 2px solid #000;
  background-color: ${props =>
    props.isActive ? props.theme.color.primaryLight : "#000"};
  margin-right: 10px;
  transition-duration: 0.1s;
  opacity: ${props => (props.isActive || props.isHovering ? 1 : 0)};
  transform: ${props =>
    props.isActive ? "scale(1)" : props.isHovering ? "scale(0.5)" : "scale(0)"};
`

const Styled = {
  Wrapper,
  NavigationLink,
  NavigationIcon,
  ActiveCircle,
}

export default Styled
